<template>
  <div class="personal-account" v-if="getUser">
    <div class="personal-account__container _container">
      <h1 class="personal-account__title main-title _blue">
        {{ $t("personal") }}
      </h1>
      <div class="personal-account__body">
        <TabItem
          :tab-items="[
            $t('my.myTickets'),
            $t('my.profile'),
            $t('my.returnTickets'),
            $t('my.myBonuses'),
          ]"
          @setTab="selectTab"
          :selected="selected"
          :color="'#ffffff'"
        ></TabItem>
        <TabBlock :isSelected="selected === $t('my.profile')">
          <div class="profile-container">
            <div class="personal-account__info personal-info">
              <Form
                @submit="onSubmit"
                v-slot="{ errors }"
                :validation-schema="schema"
                class="personal-info__form"
              >
                <div class="personal-info__item">
                  <label for="name">{{ $t("inputs.name") }}</label>
                  <Field
                    name="name"
                    :class="{ _error: errors.hasOwnProperty('name') }"
                    type="text"
                    v-model="getUser.name"
                    id="name"
                    class="personal-info__input main-input"
                  />
                  <transition name="fade">
                    <span class="error-text">{{ errors.name }}</span>
                  </transition>
                </div>
                <div class="personal-info__item">
                  <label for="phone">{{ $t("inputs.phone") }}</label>
                  <Field
                    name="phone"
                    :class="{ _error: errors.hasOwnProperty('phone') }"
                    type="text"
                    v-model="getUser.phone_number"
                    v-mask="'+7 (###) ###-##-##'"
                    id="phone"
                    class="personal-info__input main-input"
                  />
                  <transition name="fade">
                    <span
                      class="error-text"
                      v-if="errors.hasOwnProperty('phone')"
                      >{{ errors.phone }}</span
                    >
                  </transition>
                </div>
                <div class="personal-info__item">
                  <label for="birth-date">{{ $t("inputs.birthday") }}</label>
                  <DatePicker
                    class="personal-info__datepicker"
                    id="birth-date"
                    v-model="getUser.birthday"
                    :model-config="{
                      type: 'string',
                      mask: 'DD/MM/YYYY',
                    }"
                    :masks="{ L: 'DD-MM-YYYY' }"
                    :attributes="attributes"
                  >
                    <template v-slot="{ togglePopover }">
                      <div class="personal-info__date main-input">
                        <input
                          :value="getUser.birthday"
                          readonly
                          v-on:click="togglePopover"
                        />
                        <span v-on:click="togglePopover"
                          ><img src="../assets/icons/calendar-blue.svg" alt=""
                        /></span>
                      </div>
                    </template>
                  </DatePicker>
                </div>
                <div class="personal-info__item">
                  <label for="email">{{ $t("inputs.email") }}</label>
                  <Field
                    name="email"
                    :class="{ _error: errors.hasOwnProperty('email') }"
                    type="email"
                    v-model="getUser.email"
                    id="email"
                    class="personal-info__input main-input"
                  />
                  <transition name="fade">
                    <span
                      class="error-text"
                      v-if="errors.hasOwnProperty('email')"
                      >{{ errors.email }}</span
                    >
                  </transition>
                </div>
                <button class="personal-info__button blue-button" type="submit">
                  {{ $t("buttons.save") }}
                </button>
              </Form>
            </div>
          </div>
        </TabBlock>
        <TabBlock :isSelected="selected === $t('my.myTickets')">
          <div class="personal-account__tickets personal-tickets">
            <div
              class="personal-tickets__columns personal-tickets__columns_title"
            >
              <div class="personal-tickets__column">
                {{ $t("table.ticketId") }}
              </div>
              <div class="personal-tickets__column">
                {{ $t("table.ticketDownload") }}
              </div>
              <div class="personal-tickets__column">
                {{ $t("table.ticketTarif") }}
              </div>
              <div class="personal-tickets__column">
                {{ $t("table.ticketDate") }}
              </div>
              <div class="personal-tickets__column">
                {{ $t("table.ticketValidity") }}
              </div>
              <div class="personal-tickets__column">
                {{ $t("table.ticketCheck") }}
              </div>
            </div>

            <div v-for="(ticket, i) in getTickets" :key="i">
              <div
                v-for="ticketItem in ticket"
                :key="ticketItem.id"
                class="personal-tickets__columns"
              >
                <div class="personal-tickets__column">
                  {{ ticketItem.id }}
                </div>
                <div class="personal-tickets__column">
                  <router-link
                    to="/ticket"
                    v-if="!ticketItem.returned"
                    @click="openTicket(ticketItem.id)"
                  >
                    <span class="personal-tickets__icon">
                      <img src="../assets/icons/dowload_ticket.svg" alt="" />
                    </span>
                  </router-link>
                  <div class="returnedT" v-else>{{ $t("returned") }}</div>
                </div>
                <div class="personal-tickets__column">
                  {{ ticketItem.title + " " + ticketItem.age }}
                </div>
                <div
                  class="personal-tickets__column personal-tickets__column_date"
                >
                  {{
                    ticketItem.created_at.slice(8, 10) +
                    "." +
                    ticketItem.created_at.slice(5, 7) +
                    "." +
                    ticketItem.created_at.slice(0, 4) +
                    " " +
                    ticketItem.created_at.slice(11, 16)
                  }}
                </div>
                <div class="personal-tickets__column">
                  <!-- {{ dateText(validityDate(ticketItem.order.created_at)) }} -->
                  {{ ticketItem.expires }}
                </div>
                <div class="personal-tickets__column">
                  <a
                    :href="ticketItem.order.cheque"
                    v-if="ticketItem.order.cheque && !ticketItem.returned"
                    >Чек</a
                  >
                </div>
              </div>
            </div>
          </div>
          <ModalComponent class="success-modal" :showModal="showModal1">
            <div class="ticket-refund__modal refund-modal">
              <div class="refund-modal__close" @click="showModal1 = false">
                <img src="../assets/icons/x.svg" alt="" />
              </div>
              <div class="refund-modal__icon">
                <img src="../assets/icons/habar.png" alt="" />
              </div>
              <div class="refund-modal__content" v-html="$t('kassaMessage')" />
            </div>
          </ModalComponent>
        </TabBlock>
        <TabBlock :isSelected="selected === $t('my.returnTickets')">
          <div class="personal-account__refund ticket-refund" v-if="getUser.canRefund">
            <form
              @submit.prevent="sendRefund"
              class="ticket-refund__form"
              :validation-schema="schema"
            >
              <div class="ticket-refund__item">
                <div class="ticket-refund__text">
                  {{ $t("inputs.refund") }}
                </div>
              </div>
              <div class="ticket-refund__item">
                <input
                  type="text"
                  @input="nameError = false"
                  v-model="refund.name"
                  class="ticket-refund__input main-input"
                  name="refund-name"
                  :style="nameError ? 'border: 1px solid red' : ''"
                  :placeholder="$t('inputs.username')"
                />
                <transition name="fade">
                  <span class="error-message" v-if="nameError">{{
                    $t("inputs.required")
                  }}</span>
                </transition>
              </div>
              <div class="ticket-refund__item sdasd">
                <input
                  type="email"
                  @input="emailError = false"
                  v-model="refund.email"
                  class="ticket-refund__input main-input"
                  :style="emailError ? 'border: 1px solid red' : ''"
                  name="refund-email"
                  placeholder="Email"
                />
                <transition name="fade">
                  <span class="error-message" v-if="emailError">{{
                    $t("inputs.required")
                  }}</span>
                </transition>
              </div>
              <div class="ticket-refund__item">
                <input
                  type="tel"
                  @input="phoneError = false"
                  v-model="refund.phone"
                  class="ticket-refund__input main-input"
                  name="refund-phone"
                  :style="phoneError ? 'border: 1px solid red' : ''"
                  v-mask="'+7 (###) ###-##-##'"
                  :placeholder="$t('inputs.phoneNumber')"
                />
                <transition name="fade">
                  <span class="error-message" v-if="phoneError">{{
                    $t("inputs.required")
                  }}</span>
                </transition>
              </div>
              <div class="ticket-refund__item">
                <div class="ticketsLists" v-if="refundTickets.length">
                  <div
                    class="ticket-refund__input main-input"
                    v-for="(item, i) in refundTickets"
                    :key="i"
                  >
                    {{ item }}
                    <div @click="removeTicketValidate(item)">
                      <img :src="require('@/assets/icons/xx.png')" />
                    </div>
                  </div>
                </div>
                <input
                  type="text"
                  @input="errTicketValidate = false"
                  v-model="refund.addTickets"
                  maxlength="10"
                  :style="errTicketValidate ? 'border: 1px solid red' : ''"
                  class="ticket-refund__input main-input ticketsInput"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  :placeholder="$t('inputs.addTickets')"
                />
                <transition name="fade">
                  <span class="error-message" v-if="errTicketValidate">
                    {{ errTextTicketValidate }}
                  </span>
                </transition>
              </div>
              <div class="addTicketButton" @click.stop="ticketValidate">
                <img :src="require('@/assets/icons/plus.png')" />
                {{ $t("inputs.addBiletButton") }}
              </div>
              <div class="ticket-refund__item">
                <textarea
                  @input="refundError = false"
                  v-model="refund.reason"
                  class="ticket-refund__textarea"
                  :style="refundError ? 'border: 1px solid red' : ''"
                  name="refund"
                  :placeholder="$t('inputs.statement')"
                />
                <transition name="fade">
                  <span class="error-message" v-if="refundError">{{
                    $t("inputs.required")
                  }}</span>
                </transition>
              </div>
              <div class="ticket-refund__item">
                <div class="ticket-refund__title">
                  {{ $t("inputs.identification") }}
                </div>
                <PassportDropZone
                  @dropPassport="dropPassport"
                  @selectPassport="selectPassport"
                ></PassportDropZone>
                <span class="ticket-refund__file-name" v-if="refund.passport">{{
                  refund.passport.name
                }}</span>
                <transition name="fade">
                  <span class="error-message" v-if="passportError">{{
                    $t("inputs.attachFile")
                  }}</span>
                </transition>
              </div>
              <div class="ticket-refund__item">
                <div class="ticket-refund__title">
                  {{ $t("inputs.receipt") }}
                </div>
                <ReceiptDropZone
                  @dropReceipt="dropReceipt"
                  @selectReceipt="selectReceipt"
                ></ReceiptDropZone>
                <span class="ticket-refund__file-name" v-if="refund.receipt">{{
                  refund.receipt.name
                }}</span>

                <transition name="fade">
                  <span class="error-message" v-if="receiptError">{{
                    $t("inputs.attachFile")
                  }}</span>
                </transition>
              </div>
              <div class="ticket-refund__item">
                <button type="submit" class="ticket-refund__button blue-button">
                  {{ $t("buttons.send") }}
                </button>
              </div>
            </form>
          </div>
          <div v-else>{{ $t('asdasd') }}</div>
          <ModalComponent :showModal="showModal" @hideModal="showModal = false">
            <div class="ticket-refund__modal refund-modal">
              <div class="refund-modal__icon">
                <img src="../assets/icons/success.svg" alt="" />
              </div>
              <h2 class="refund-modal__title">{{ $t("my.appSuccess") }}</h2>
              <div class="refund-modal__text">
                {{ $t("my.expectRefund") }}
              </div>
            </div>
          </ModalComponent>
        </TabBlock>
        <TabBlock :isSelected="selected === $t('my.myBonuses')">
          <div class="loyalty">
            <div class="bonus">
              <div class="bonus-top">
                <img src="@/assets/images/coins.png" alt="" />
                <div class="bonus-top__coin">
                  <p class="bonus-top__coin-title">{{ getUser.bonus }}</p>
                  <p class="bonus-top__coin-desc">
                    {{ $t("my.bonusesAccumulated") }}
                  </p>
                </div>
              </div>
              <div class="bonus-md">
                <p>+ 5% кэшбек</p>
              </div>
              <h5 class="bonus-bottom">
                {{ $t("my.accrue") }}
              </h5>
            </div>
            <button class="bonus-btn">
              <router-link to="/loyalty-program">{{
                $t("my.loyality")
              }}</router-link>
            </button>
          </div>
        </TabBlock>
      </div>
    </div>
  </div>
</template>

<script>
import TabItem from "@/components/TabItem";
import TabBlock from "@/components/TabBlock";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import axios from "axios";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import PassportDropZone from "@/components/DropzonePassport";
import ReceiptDropZone from "@/components/DropzoneReceipt";
import { useToast } from "vue-toastification";
import ModalComponent from "@/components/ModalComponent";
import { Calendar, DatePicker } from "v-calendar";
import "v-calendar/dist/style.css";

export default {
  components: {
    ModalComponent,
    ReceiptDropZone,
    PassportDropZone,
    TabBlock,
    TabItem,
    Field,
    Form,
    DatePicker,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    const schema = Yup.object().shape({
      name: Yup.string().required(this.$t("errors.required")),
      phone: Yup.string()
        .min(18, this.$t("errors.minLenght", { value: 18 }))
        .required(this.$t("errors.required"))
        .label("Name")
        .nullable(),
      email: Yup.string()
        .email(this.$t("errors.email"))
        .required(this.$t("errors.required")),
    });
    return {
      selected: "Профиль",
      schema,
      showModal: false,
      showModal1: false,
      refundError: false,
      passportError: false,
      receiptError: false,
      errTicketValidate: false,
      errTextTicketValidate: "",
      nameError: false,
      emailError: false,
      phoneError: false,
      refundTickets: [],
      bonus: [],
      refund: {
        name: "",
        email: "",
        phone: "",
        reason: "",
        passport: "",
        receipt: "",
        addTickets: "",
      },
      attributes: [
        {
          highlight: {
            backgroundColor: "#ff8080", // Red background
            borderColor: "#ff6666",
            borderWidth: "2px",
            borderStyle: "solid",
          },
        },
      ],
    };
  },

  created() {
    this.fetchTickets();
    this.requestUser();

    if (!this.getIsAuth) {
      this.$router.push("/");
    }

    if (localStorage.getItem("success_payment") == "true") {
      // this.showModal1 = true;
      // if (this.showModal1) {
      //   // document.documentElement.style.overflow = "hidden";
      // } else {
      //   document.documentElement.style.overflow = "auto";
      // }
      // setTimeout(() => {
      this.$store.commit("REMOVE_CART");
      localStorage.setItem("success_payment", "false");
      this.$router.push("/thank-you");
      //   window.location.reload();
      // }, 10000);
    }
  },
  mounted() {
    this.fetchTickets();
    this.requestUser();
  },
  methods: {
    ...mapMutations(["SET_TICKETID"]),
    ...mapActions(["fetchTickets", "requestUser"]),
    selectTab(tab) {
      this.selected = tab;
      if (tab === this.$t("my.myTickets")) {
        // this.showModal1 = true;
      }
    },
    removeTicketValidate(value) {
      this.refundTickets = this.refundTickets.filter((item) => item !== value);
    },
    ticketValidate() {
      if (this.refund.addTickets.length > 2) {
        axios
          .get(
            `https://admin.san-remo.kz/api/V1/ticket-refund-check?tickets=${this.refund.addTickets}`
          )
          .then((res) => {
            this.refundTickets.push(parseInt(this.refund.addTickets));
            this.refund.addTickets = "";
          })
          .catch((err) => {
            if (err.response.data.message === "Ticket not Found") {
              this.errTextTicketValidate = this.$t("inputs.ticketNotFound")
            } else if (err.response.data.message === "Tickets expired") {
              this.errTextTicketValidate = this.$t("inputs.timeTicket1")
            } else {
              this.errTextTicketValidate = this.$t("inputs.timeTicket")
            }
            this.errTicketValidate = true;
          });
      } else {
        this.errTextTicketValidate = this.$t("inputs.minLengtTicket");
        this.errTicketValidate = true;
      }
    },
    openTicket(id) {
      this.SET_TICKETID(id);
    },
    dropPassport(file) {
      this.refund.passport = file;
    },
    dropReceipt(file) {
      this.refund.receipt = file;
    },
    selectPassport(file) {
      this.refund.passport = file;
      this.passportError = false;
    },
    selectReceipt(file) {
      this.refund.receipt = file;
      this.receiptError = false;
    },
    validityDate(date) {
      const oneDay = 24 * 60 * 60 * 1000;
      const firstDate = new Date();
      const secondDate = new Date(date);
      const diffDays = Math.round(
        Math.abs(30 - (firstDate - secondDate) / oneDay)
      );
      return diffDays;
    },
    dateText(date) {
      return date == 1 || (date > 19 && date % 10 == 1)
        ? `${date} день`
        : (date > 1 && date < 5) ||
          (date > 19 && date % 10 > 1 && date % 10 < 5)
        ? `${date} дня`
        : `${date} дней`;
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString(
        localStorage.getItem("lang") === "ru" ? "ru-RU" : "kk-KK",
        {
          year: "numeric",
          day: "numeric",
          month: "short",
        }
      );
    },
    onSubmit() {
      axios
        .post("https://admin.san-remo.kz/api/V1/user-update", {
          name: this.getUser.name,
          phone_number: this.getUser.phone_number,
          email: this.getUser.email,
          birthday: this.getUser.birthday,
          id: this.getUser.id,
        })
        .then((res) => {
          if (res.status === 200) {
            this.SET_INFO(res.data.user);
            this.requestUser();
            this.$router.push("/personal-account");
          }
          this.toast.success(this.$t("toast2"));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async sendRefund() {
      let formatData = new FormData();
      formatData.append("name", this.refund.name);
      formatData.append("email", this.refund.email);
      formatData.append("phone", this.refund.phone);
      formatData.append("refund", this.refund.reason);
      formatData.append("passport", this.refund.passport);
      formatData.append("receipt", this.refund.receipt);
      if (this.refund.addTickets.length >= 3) {
        await axios
          .get(
            `https://admin.san-remo.kz/api/V1/ticket-refund-check?tickets=${this.refund.addTickets}`
          )
          .then((res) => {
            this.refundTickets.push(parseInt(this.refund.addTickets));
            formatData.append("tickets", this.refundTickets);
            this.refund.addTickets = "";
            this.sendGo(formatData);
          })
          .catch((err) => {
            if (err.response.data.message === "Ticket not Found") {
              this.errTextTicketValidate = this.$t("inputs.ticketNotFound")
            } else if (err.response.data.message === "Tickets expired") {
              this.errTextTicketValidate = this.$t("inputs.timeTicket1")
            } else {
              this.errTextTicketValidate = this.$t("inputs.timeTicket")
            }
            this.errTicketValidate = true;
            document.querySelector(".sdasd").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          });
      } else if (
        this.refund.addTickets.length < 4 &&
        this.refundTickets.length < 1
      ) {
        this.errTextTicketValidate = this.$t("inputs.minLengtTicket");
        this.errTicketValidate = true;
        document.querySelector(".sdasd").scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      } else if (
        this.refund.addTickets === "" &&
        this.refundTickets.length > 0
      ) {
        formatData.append("tickets", this.refundTickets);
        this.sendGo(formatData);
      }
    },
    async sendGo(formatData) {
      await axios
        .post(
          "https://admin.san-remo.kz/api/V1/ticket-refund",
          formatData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            this.showModal = true;
            this.refund.name = "";
            this.refund.email = "";
            this.refund.phone = "";
            this.refund.reason = "";
            this.refund.passport = "";
            this.refund.receipt = "";
            this.toast.success(this.$t("inputs.zyavakaSend"));
            this.selected = this.$t("my.profile");
            this.refundTickets = [];
            this.requestUser()
          }
        })
        .catch((error) => {
          let errors = error.response.data.errors;
          if (Object.prototype.hasOwnProperty.call(errors, "name")) {
            this.nameError = true;
          } else this.nameError = false;
          if (Object.prototype.hasOwnProperty.call(errors, "phone")) {
            this.phoneError = true;
          } else this.phoneError = false;
          if (Object.prototype.hasOwnProperty.call(errors, "email")) {
            this.emailError = true;
          } else this.emailError = false;
          if (Object.prototype.hasOwnProperty.call(errors, "refund")) {
            this.refundError = true;
          } else this.refundError = false;
          if (Object.prototype.hasOwnProperty.call(errors, "receipt")) {
            this.receiptError = true;
          } else this.receiptError = false;
          if (Object.prototype.hasOwnProperty.call(errors, "passport")) {
            this.passportError = true;
          } else this.passportError = false;
          if (!this.refundTickets.length) {
            this.errTicketValidate = true;
            this.errTextTicketValidate = this.$t("inputs.addBilet");
          } else this.errTicketValidate = false;
          this.toast.warning(this.$t("inputs.polia"));
        });
    },
  },
  watch: {
    showModal1() {
      this.showModal1
        ? (document.documentElement.style.overflowY = "hidden")
        : (document.documentElement.style.overflowY = "auto");
    },
  },
  computed: {
    ...mapGetters(["getUser", "getTickets", "getIsAuth", "getLang"]),
    ...mapState(["tickets"]),
  },
};
</script>

<style lang="scss">
.profile-container {
  display: flex;
  justify-content: space-between;

  @media (max-width: 1309.9px) {
    flex-direction: column;
    gap: 32px;
  }
}

.loyalty {
  @media (max-width: 1309.9px) {
    text-align: center;
  }
}

.bonus {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  width: 100%;
  background: white;
  padding: 48px 0 24px 0;
  border: 1px solid #0000001A;
  &-top {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 356.9px) {
      flex-direction: column;
    }

    img {
      margin-right: 37px;
      max-width: 168px;
      width: 100%;
      object-fit: cover;
    }

    &__coin {
      align-items: center;
      display: flex;
      flex-direction: column;

      &-title {
        font-family: "Nunito";
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        font-size: calc(36px + 12 * (100vw / 1660));
        line-height: 65px;
        letter-spacing: 0.02em;
        color: #209E2F;
      }

      &-desc {
        font-family: "Nunito";
        font-style: normal;
        font-weight: 600;
        font-size: calc(18px + 6 * (100vw / 1660));
        line-height: 33px;
        letter-spacing: 0.02em;
        color: #209E2F;
      }
    }
  }

  &-md {
    background: transparent;
    margin: 48px 0 0 0;
    padding: 14px 0;

    p {
      text-align: center;
      font-family: "Nunito";
      font-style: normal;
      font-weight: 700;
      font-size: calc(20px + 12 * (100vw / 1660));
      line-height: 44px;
      letter-spacing: 0.02em;
      color: #4745B0;
    }
  }

  &-bottom {
    text-align: center;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: calc(14px + 6 * (100vw / 1660));
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #626262;
    padding: 24px;
    width: 90%;
    margin: 0 auto;
  }

  &-btn {
    background: #209E2F ;
    border-radius: 15px;
    max-width: 540px;
    width: 100%;
    padding: 18px 24px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: calc(16px + 6 * (100vw / 1320));
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ffffff;
    margin-top: 32px;

    &-second {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 700;
      font-size: calc(16px + 12 * (100vw / 1660));
      line-height: 38px;
      text-align: center;
      letter-spacing: 0.02em;
      text-decoration-line: underline;
      color: #ffffff;
      margin-top: 32px;
      max-width: 525px;

      &:hover {
        opacity: 0.6;
        transition: 0.6s;
      }
    }

    &:hover {
      background-color: #1a58b9;
      transition: 0.6s;
    }
  }

  @media (max-width: 1309.9px) {
    margin: 0 auto;
  }
}

.personal-account {
  padding: 120px 0 130px 0;
  background: #44C45F;
  @media (max-width: $tablet + px) {
    padding: 65px 0;
  }

  @media (max-width: $mobileSmall + px) {
    padding: 32px 0;
  }

  &__title {
    margin: 0 0 150px 0;

    @media (max-width: $tablet + px) {
      margin: 0 0 107px 0;
    }

    @media (max-width: $mobileSmall + px) {
      margin: 0 0 63px 0;
    }
  }

  .tab-content {
    padding: 70px 0 0 0;

    @media (max-width: $mobileSmall + px) {
      padding: 16px 0 0 0;
    }
  }
}

.personal-info {
  max-width: 940px;

  @media (max-width: $tablet + px) {
    max-width: 800px;
  }

  &__form {
    margin: 0 0 48px 0;

    @media (max-width: $tablet + px) {
      margin: 0 0 40px 0;
    }

    @media (max-width: $mobileSmall + px) {
      margin: 0 0 32px 0;
    }
  }

  &__item {
    position: relative;

    @media (min-width: $mobile + px) {
      display: flex;
      align-items: center;
      gap: 0 60px;
    }

    label {
      font-weight: 600;
      font-size: 20px;
      line-height: 165%;
      letter-spacing: 0.02em;
      color: #ffffff;

      @media (max-width: $tablet + px) {
        font-size: 17px;
      }

      @media (max-width: $mobileSmall + px) {
        font-size: 14px;
        margin: 0 0 5px 0;
        display: block;
      }

      @media (min-width: $mobileSmall + px) {
        width: 250px;
        min-width: 250px;
      }
    }

    &:not(:last-child) {
      margin: 0 0 32px 0;

      @media (max-width: $tablet + px) {
        margin: 0 0 28px 0;
      }

      @media (max-width: $mobileSmall + px) {
        margin: 0 0 24px 0;
      }
    }

    .error-text {
      right: 0 !important;
      text-align: right;
    }
  }

  &__input {
    width: 100%;
  }

  &__button {
    @media (min-width: $mobile + px) {
      width: 250px !important;
      margin: 0 0 0 310px;
    }
  }

  &__datepicker {
    width: 100%;
  }

  &__date {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      width: 100%;
      font-weight: 600;
      font-size: 20px;
      letter-spacing: 0.02em;
      color: #ffffff;

      @media (max-width: $tablet + px) {
        font-size: 17.5px;
      }

      @media (max-width: $mobileSmall + px) {
        font-size: 15px;
      }
    }

    span {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;

      img {
        width: 22.5px;
        height: 22.5px;
      }

      @media (max-width: $tablet + px) {
        width: 25px;
        height: 25px;

        img {
          width: 18.75px;
          height: 18.75px;
        }
      }

      @media (max-width: $mobileSmall + px) {
        width: 20px;
        height: 20px;

        img {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

.ticket-refund {
  &__item {
    position: relative;

    &:not(:last-child) {
      margin: 0 0 32px 0;

      @media (max-width: $tablet + px) {
        margin: 0 0 36px 0;
      }

      @media (max-width: $mobileSmall + px) {
        margin: 0 0 24px 0;
      }
    }

    @media (max-width: $mobileSmall + px) {
      &:first-child {
        margin: 0 0 16px 0;
      }
    }
  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 138.88%;
    letter-spacing: 0.02em;
    color: #2c3131;

    @media (max-width: $tablet + px) {
      font-size: 16px;
    }

    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 137.5%;
    letter-spacing: 0.02em;
    color: #ffffff;
    margin: 0 0 16px 0;

    @media (max-width: $tablet + px) {
      font-size: 20px;
      margin: 0 0 14px 0;
    }

    @media (max-width: $mobileSmall + px) {
      margin: 0 0 12px 0;
      font-size: 16px;
    }

    &::after {
      content: "*";
      color: #f52424;
    }
  }

  &__input {
    width: 100%;
    max-width: 680px;
    font-size: 18px !important;

    @media (max-width: $mobile + px) {
      padding: 16px !important;
      font-size: 14px !important;
    }

    @media (max-width: $mobile + px) {
      padding: 16px !important;
      font-size: 14px !important;
    }
  }

  &__textarea {
    width: 100%;
    height: 160px;
    resize: none;
    padding: 23px 24px;
    border: 1px solid #ffffff;
    background: #ffffff;
    border-radius: 15px;

    @media (min-width: $mobile + px) {
      width: 680px;
      height: 250px;
    }

    @media (max-width: $mobile + px) {
      padding: 16px !important;
      font-size: 14px !important;
    }
  }

  &__file-name {
    display: inline-block;
    text-decoration: underline;
    margin: 20px 0 0 0;

    @media (max-width: $tablet + px) {
      font-size: 16px;
    }

    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
      margin: 15px 0 0 0;
    }
  }

  &__button {
    max-width: 314px;

    @media (max-width: $mobileSmall + px) {
      max-width: 170px;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin: 0 8px 0 0;

    img {
      width: 13.33px;
      height: 13.33px;
    }
  }

  &__small-text {
    font-size: 18px;
    line-height: 138.888%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #2c3131;
  }
}

.refund-modal {
  max-width: 100%;
  padding: 74px;
  gap: 38px;
  display: flex;
  align-items: center;
  @media (max-width: $tablet + px) {
    padding: 24px 0;
    gap: 10px;
    padding: -30px -10px;
  }
  &__close {
    position: absolute;
    right: 37px;
    top: 37px;
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
    }
    @media (max-width: $tablet + px) {
      right: 17px;
      top: 17px;
    }
  }
  &__icon {
    width: 150px;
    margin: 0 auto 18px;

    img {
      width: 120px;
      height: 120px;
    }

    @media (max-width: $tablet + px) {
      width: 100px;
      height: 100px;

      img {
        width: 90px;
        height: 90px;
      }
    }

    @media (max-width: $mobileSmall + px) {
      width: 80px;
      height: 80px;
      margin: 0 auto 10px;

      img {
        width: 70px;
        height: 70px;
      }
    }
  }

  h1 {
    font-weight: 700;
    font-size: 21.76px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #ff6969;

    @media (max-width: $mobileSmall + px) {
      font-size: 20px;
      margin: 0 0 10px 0;
    }
  }

  &__content {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    width: 358px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #2c3131;

    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
      width: 80%;
    }
  }
}

.personal-tickets {
  @media (max-width: $mobile + px) {
    overflow-x: scroll;
  }

  &__columns {
    display: grid;
    grid-template-columns: 15% 15% 20% 20% 20% 10%;

    @media (max-width: $tablet + px) {
      grid-template-columns: repeat(5, 15%) 140px;
    }

    @media (max-width: $mobile + px) {
      grid-template-columns: repeat(5, 150px) 120px;
    }

    @media (max-width: $mobileSmall + px) {
      grid-template-columns: repeat(5, 130px) 110px;
    }

    &:not(:first-child) {
      margin: 0 0 40px 0;

      @media (max-width: $mobileSmall + px) {
        margin: 0 0 20px 0;
      }
    }

    &:not(:last-child) {
      margin: 0 0 32px 0;

      @media (max-width: $mobileSmall + px) {
        margin: 0 0 20px 0;
      }
    }

    &:last-child {
      margin: 0;
    }

    &_title {
      white-space: nowrap;
      padding: 0 0 32px 0;
      border-bottom: 1px solid rgba(37, 110, 216, 0.8);
      margin: 0 0 36px 0;

      @media (max-width: $tablet + px) {
        padding: 0 0 25px 0;
        margin: 0 0 26px 0;
      }

      @media (max-width: $mobile + px) {
        width: 93vh;
      }

      @media (max-width: $mobileSmall + px) {
        width: 80vh;
        padding: 0 0 16px 0;
        margin: 0 0 16px 0;
      }
    }
  }

  &__column {
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: #ffffff;

    &:not(:last-child) {
      padding: 0 20px 0 0;
    }

    &_date {
      white-space: nowrap;
    }

    @media (min-width: $mobile + px) {
      &:last-child {
        text-align: center;
      }
    }

    @media (max-width: $tablet + px) {
      font-size: 16.5px;

      &:not(:last-child) {
        padding: 0 18px 0 0;
      }
    }

    @media (max-width: $mobileSmall + px) {
      font-size: 13px;

      &:not(:last-child) {
        padding: 0 16px 0 0;
      }
    }
  }

  &__icon {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 23.33px;
      height: 23.33px;
    }

    @media (max-width: $tablet + px) {
      width: 27.5px;
      height: 27.5px;

      img {
        width: 18.33px;
        height: 18.33px;
      }
    }

    @media (max-width: $mobileSmall + px) {
      width: 20px;
      height: 20px;

      img {
        width: 13.33px;
        height: 13.33px;
      }
    }

    //@media (min-width: $mobile + px) {
    //  margin: 0 auto;
    //}
  }
}

.success-modal {
  .modal__close {
    display: none;
  }
}

.error-message {
  bottom: 5px !important;

  @media (max-width: $mobileSmall + px) {
    bottom: 12px !important;
  }
}

.ticketsLists {
  display: flex;
  flex-direction: column;
  max-width: 680px;
  width: 100%;
  gap: 16px;
  margin: 32px 0;
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    div img {
      width: 20px;
      cursor: pointer;
    }
  }
}

.addTicketButton {
  display: flex;
  align-items: center;
  gap: 10px;
  color:  #ffffff;
  font-size: 22px;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 32px;
}

.returnedT {
  color: red;
  font-weight: 500;
}
</style>
