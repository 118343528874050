<template>
  <swiper
    :slidesPerView="1"
    :navigation="navigation"
    :pagination="{ clickable: true }"
    :speed="700"
    :effect="'fade'"
    :autoplay="{ delay: 4000 }"
    :allowTouchMove="false"
    class="home-slider"
  >
    <swiper-slide
      class="home-slider__slide"
      v-for="(slide, i) in mainSlides"
      :key="i"
    >
      <div class="mobile">
        <img class="introAbsolute" :src="'https://admin.san-remo.kz/'+slide.background_mob" :alt="i+'img'" />
      </div>
      <div class="descktop">
        <img class="introAbsolute" :src="'https://admin.san-remo.kz/'+slide.background" :alt="i+'img'" />
      </div>
      <div class="home-slider__body">
        <div class="home-slider__content">
          <h1 class="home-slider__title" v-html="slide.title" />
          <h2
            class="home-slider__small-title"
            style="white-space: nowrap"
            v-html="slide.desc"
          />
          <div class="newButtonSlider">
            <button
              @click="scrollToSection('stock')"
              class="home-slider__button base-button"
            >
              {{ $t("sales") }}</button
            >
            <a href="https://san-remo.kz/events/kopi-bonusy-pokupai-bilety-v-akvapark"><button
              class="home-slider__button base-button"
              style="color: #209E2F; background-color: white;"
            >
            {{ $t("giveBonus") }}</button
            ></a>
            <!-- <a
              href="https://youtube.com/shorts/rcb0yy0E0B4?feature=share"
              target="_blank"
              class="home-slider__button base-button"
              style="
                background: #ffffff;
                box-shadow: 5px 4px 16px rgba(255, 139, 91, 0.4);
                border-radius: 30px;
                color: #39A24A;
                /* outline: 1px solid #ffdb33; */
              "
            >
              {{ $t("howBilets") }}
            </a> -->
          </div>
        </div>
        <div class="home-slider__images">
          <div
            class="home-slider__image _ibg"
            v-for="(smallImage, i) in slide.smallImages"
            :key="i"
          >
            <!-- <img :src="smallImage" alt="" /> -->
          </div>
        </div>
      </div>
    </swiper-slide>
    <!-- <div class="best">
      <img :src="require('@/assets/images/home-slider/best.png')" alt="" />
    </div> -->
    <div class="home-slider__navigation">
      <div class="main-swiper-button-prev">
        <img src="@/assets/icons/arrow.svg" alt="" />
      </div>
      <div class="main-swiper-button-next">
        <img src="@/assets/icons/arrow.svg" alt="" />
      </div>
    </div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import axios from "axios";

import SwiperCore, {
  EffectFade,
  Navigation,
  Pagination,
  Autoplay,
} from "swiper";

SwiperCore.use([Pagination, Navigation, EffectFade, Autoplay]);
export default {
  name: "main-slider",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      mainSlides: [
        {
          title: this.$t("main.introTitle"),
          text: this.$t("main.introDesc"),
          mainImage: "",
          smallImages: [
            require("@/assets/images/home-slider/02.jpg"),
            require("@/assets/images/home-slider/03.jpg"),
            require("@/assets/images/home-slider/01.jpg"),
          ],
        },
        {
          title: this.$t("main.introTitle"),
          text: this.$t("main.introDesc"),
          smallImages: [
            require("@/assets/images/home-slider/02.jpg"),
            require("@/assets/images/home-slider/03.jpg"),
            require("@/assets/images/home-slider/01.jpg"),
          ],
        },
        {
          title: this.$t("main.introTitle"),
          text: this.$t("main.introDesc"),
          smallImages: [
            require("@/assets/images/home-slider/02.jpg"),
            require("@/assets/images/home-slider/03.jpg"),
            require("@/assets/images/home-slider/01.jpg"),
          ],
        },
      ],
      navigation: {
        nextEl: ".main-swiper-button-next",
        prevEl: ".main-swiper-button-prev",
      },
    };
  },
  mounted() {
    axios.get('V1/slider').then(res => {
      this.mainSlides = res.data.data
    }).catch(err => console.log(err))
  },
  methods: {
    scrollToSection(link) {
      this.$emit("scroll", link);
    },
  },
};
</script>

<style lang="scss">
.home-slider {
  .swiper-pagination {
    bottom: 131.83px !important;

    &-bullet {
      opacity: 1;
      background: #b3b3bb;
      width: 8.33px;
      height: 8.33px;
      margin: 0 12.5px 0 !important;

      &-active {
        background: #ffffff;
      }

      @media (max-width: $mobile+ px) {
        width: 4.17px;
        height: 4.17px;
        margin: 0 6.25px !important;
      }
    }

    @media (max-width: $mobile + px) {
      bottom: 42.92px !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.newButtonSlider {
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: $tablet + px) {
    align-items: flex-start;
    padding: 0;
    gap: 10px;
  }
  @media (max-width: $mobileSmall + px) {
    margin-top: 0;
    button,
    a {
      font-size: 10px;
    }
  }
}
.best {
  position: absolute;
  top: 40px;
  right: 0%;
  width: 150px;
  z-index: 999;
  img {
    width: 100%;
    height: 100%;
  }
  @media (min-width: 1750px) {
    right: 6%;
    width: 200px;
  }
  @media (max-width: 1500px) {
    width: 180px;
  }
  @media (max-width: $tablet + px) {
    width: 150px;
    right: 10%;
    top: 10px;
  }
  @media (max-width: 768px) {
    width: 110px;
    right: 10%;
    top: 10px;
  }
  @media (max-width: $mobileSmall + px) {
    width: 80px;
    right: 5%;
  }
}
.home-slider {
  position: relative;
  width: 100%;
  .swiper-slide {
    position: relative;
    padding: 150px 0 0 0;
    height: 800px;
    /* background: no-repeat
        linear-gradient(0deg, rgba(33, 129, 152, 0.1), rgba(33, 129, 152, 0.5)),
      url("@/assets/images/home-slider/HeroBlock.jpg"), #ffffff; */
    background-size: cover;

    &:before {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 75.58px;
      background: no-repeat url("@/assets/icons/wave.svg");
      background-size: cover;
    }

    @media (max-width: $mobile + px) {
      height: 280px;
      padding: 65px 0 0 0;
      &:before {
        height: 18.26px;
      }
    }
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 256px 0 270px;
    gap: 0 50px;
    @media (max-width: 1660px) {
      padding: 0 200px;
      gap: 0 25px;
    }
    @media (max-width: $laptop + px) {
      justify-content: space-around;
    }
    @media (max-width: $tablet + px) {
      padding: 0 30px;
      gap: 0 13px;
    }
    @media (max-width: $mobileSmall + px) {
      padding: 0 16px;
    }
  }

  .main-swiper-button-prev,
  .main-swiper-button-next {
    cursor: pointer;
    display: none;
    @media (min-width: $tablet + px) {
      display: block;
      position: absolute;
      top: 350px;
      width: 50px;
      height: 50px;
      background: #39A24A;
      padding: 18px 16px;
      border-radius: 50%;
      z-index: 21;
    }
  }

  .main-swiper-button-prev {
    left: 130px;
    @media (max-width: 1660px) {
      left: 40px;
    }
  }

  .main-swiper-button-next {
    right: 130px;
    @media (max-width: 1660px) {
      right: 40px;
    }

    img {
      transform: rotate(180deg);
    }
  }

  &__title {
    max-width: 550px;
    display: block;
    font-family: "Kalam";
    font-weight: 700;
    font-size: 64px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #ffffff;
    text-shadow: 2px 2px 5px #256ed8;
    margin: 0 0 5px 0;
    @media (max-width: $laptop + px) {
      font-size: 50px;
    }
    @media (max-width: $mobile + px) {
      font-size: 28px;
      line-height: 28px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 20px;
      margin: 0;
    }
  }

  &__small-title {
    display: block;
    margin: 0 0 16px 0;
    font-family: "Comfortaa";
    font-weight: 500;
    font-size: 32px;
    line-height: 112.5%;
    letter-spacing: 0.02em;
    color: #ffffff;
    @media (max-width: $laptop + px) {
      font-size: 25px;
    }
    @media (max-width: $mobile + px) {
      font-size: 17px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
      margin: 0 0 5px 0;
    }
  }

  &__button {
    @media (max-width: $mobile + px) {
      font-size: 12px;
      padding: 8px 16px;
    }
  }

  &__images {
    position: relative;
    flex: 0 0 654px;
    height: 451px;
    @media (max-width: $laptop + px) {
      height: 351px;
      flex: 0 0 500px;
    }
    @media (max-width: $mobile + px) {
      flex: 0 0 120px;
      height: 111px;
    }
  }

  &__image {
    position: absolute;
    box-shadow: 2px 2px 80px #218198;
    border: 3px solid #218198;
    overflow: hidden;
    @media (max-width: $mobile + px) {
      border-width: 2px;
    }

    &.image-1,
    &.image-3 {
      top: 205px;
      z-index: 2;
      @media (max-width: $mobile + px) {
        top: 51px;
      }
    }

    &.image-1 {
      width: 183px;
      height: 188px;
      left: 0;
      border-radius: 42% 58% 62% 38% / 52% 44% 56% 48%;
      @media (max-width: $laptop + px) {
        width: 153px;
        height: 159px;
      }
      @media (max-width: $mobile + px) {
        width: 45px;
        height: 46px;
      }
    }

    &.image-2 {
      width: 419px;
      height: 451px;
      left: 74px;
      background-color: #3360c0;
      border-radius: 65% 33% 34% 46% / 48% 42% 22% 48%;

      img {
        opacity: 0.8;
      }

      @media (max-width: $laptop + px) {
        width: 300px;
        height: 331px;
      }
      @media (max-width: $mobile + px) {
        left: 18px;
        width: 102px;
        height: 111px;
      }
    }

    &.image-3 {
      width: 254px;
      height: 217px;
      right: 0;
      border-radius: 39% 61% 56% 44% / 50% 55% 45% 50%;
      @media (max-width: $laptop + px) {
        width: 204px;
        height: 167px;
      }
      @media (max-width: $mobile + px) {
        width: 62px;
        height: 54px;
      }
    }
  }
}
.introAbsolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  object-fit: cover;
  height: 100%;
}
</style>
